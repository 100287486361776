/**
 *
 * Loading
 *
 */
import { ReactComponent as GMLogoIcon } from "./assets/logo_galactic_menu_icon_faded.svg"

interface Props {}

export function Loading(props: Props) {
  return (
    <div className="flex absolute w-full h-full items-center justify-center mx-auto">
      <div className="z-100 animate-pulse mx-auto my-auto">
        <GMLogoIcon className="h-48 w-48 mx-auto my-auto" />
      </div>
    </div>
  )
}
