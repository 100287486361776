import { useState } from "react"
import { useQuery } from "react-query"
import { ShowMenuAPI } from "./index.d"
import "./App.css"
import MenuHeader from "./components/MenuHeader"
import MenuItems from "./components/MenuItems"
import MenuSections from "./components/MenuSections"
import { Loading } from "./components/Loading"
import { REACT_APP_VERSION } from "./config/env"

const browserLanguage = window.navigator.language.split("-")[0]

const App = () => {
  const url = new URL(window.location.href)

  const getLocationMenuData = async (key: string): Promise<ShowMenuAPI> => {
    return await fetch(`https://restomenu-show-api.incognos.workers.dev${url.pathname}`, { headers: { Accept: "application/json" } }).then(
      (res) => res.json()
    )
  }

  const location = useQuery<ShowMenuAPI>(["location"], async () => await getLocationMenuData(url.pathname.replace("/", "")))

  const [selectedLanguage, setSelectedLanguage] = useState(browserLanguage)

  console.log("location", location.data)

  if (!location.data || location.isLoading) {
    return <Loading />
  }

  if (!location.data.success) {
    return <div>Error: {location.data.error}</div>
  }

  if (!location.data.location) {
    return <div>Fail</div>
  }

  const locationData = location.data.location

  return (
    <div>
      <div className="mt-2">
        <div className="bg-white shadow border rounded-md p-2">
          <MenuHeader selectedLang={selectedLanguage} setSelectedLang={setSelectedLanguage} location={locationData} />
          <MenuSections selectedLang={selectedLanguage} menus={locationData.menuData} />
          <div className="overflow-y-auto scroll-pt-4 snap-y h-phone-height overscroll-contain">
            <MenuItems selectedLang={selectedLanguage} currency={locationData.currency} menus={locationData.menuData} />
          </div>
        </div>
      </div>
      <p className="text-gray-400 xl:text-center text-xs pb-3">Version: {REACT_APP_VERSION}</p>
    </div>
  )
}

export default App
