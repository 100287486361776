import { AllergenIconSolid } from "../AllergenIconSolid"
import { Tag } from "../../index.d"

interface Props {
  tags: Tag[]
  showName?: boolean
  showDescription?: boolean
  className?: string
}
export const AllergenIcons = (props: Props) => {
  const { tags, showName, showDescription, className } = props

  if (!tags) {
    return null
  }

  return (
    <>
      {tags.map((tag) => {
        return <AllergenIconSolid tag={tag} key={tag.id} showName={showName} showDescription={showDescription} className={className} />
      })}
    </>
  )
}
