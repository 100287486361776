/**
 *
 * AllergenIconSolid
 *
 */
import { memo } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faShrimp,
  faSalad,
  faLeaf,
  faPig,
  faPeanut,
  faWheat,
  faFish,
  faEgg,
  faFarm,
  faTurkey,
  faCow,
  faCircleQuestion,
  IconDefinition,
} from "@fortawesome/pro-solid-svg-icons"
import { TagType } from "../../index.d"
import { useTranslation } from "react-i18next"

interface Props {
  tag: TagType | undefined
  showName?: boolean
  showDescription?: boolean

  className?: string
}

export const AllergenIconSolid = memo((props: Props) => {
  const { t } = useTranslation()
  const { tag, showDescription, showName, className } = props

  const icon: { [key: string]: { faIcon: IconDefinition; color: string } } = {
    dairy: { faIcon: faFarm, color: "#c46800" },
    eggs: { faIcon: faEgg, color: "#f9bf3b" },
    gluten: { faIcon: faWheat, color: "#ffd165" },
    fish: { faIcon: faFish, color: "#18a0d6" },
    shellfish: { faIcon: faShrimp, color: "#420f05" },
    pork: { faIcon: faPig, color: "#ff89c4" },
    nut: { faIcon: faPeanut, color: "#865e00" },
    beef: { faIcon: faCow, color: "#8a5300" },
    chicken: { faIcon: faTurkey, color: "#f9bf3b" },
    vegetarian: { faIcon: faSalad, color: "#0e7c00" },
    vegan: { faIcon: faLeaf, color: "#06be00" },
  }

  if (tag !== undefined) {
    return (
      <>
        <FontAwesomeIcon
          className={className}
          icon={icon[tag.id].faIcon || faCircleQuestion}
          color={icon[tag.id].color}
          title={tag.description["en"]}
        />{" "}
        {showName && <p>{t(`common.tag_${tag.id}`, tag.name["en"])}</p>}
        {showDescription && <span className="text-sm text-gray-400">{t(`common.tag_desc_${tag.id}`, tag.description["en"])}</span>}
      </>
    )
  } else {
    return (
      <>
        <FontAwesomeIcon className={className} icon={faCircleQuestion} title={"invalid tag"} /> --
      </>
    )
  }
})
