import { Menu, MultiPrice, Currency } from "../../index.d"
import { AllergenIcons } from "../AllergenIcons"

interface MenuItemsProps {
  selectedLang: string
  currency: Currency
  menus: Menu[]
}

const MenuItems = (props: MenuItemsProps) => {
  const { menus, selectedLang, currency } = props

  const priceDisplay = (priceType: string, price: number, multiPrice: MultiPrice[]) => {
    switch (priceType) {
      case "NONE":
        return ""
      case "MARKET":
        return "Market Price"
      case "MULTI":
        return Object.keys(multiPrice)
          .map((item) => `${multiPrice[item].name[selectedLang]}: ${currency.symbol} ${multiPrice[item].price}`)
          .join(" / ")
      default:
        return `${currency.symbol} ${price}`
    }
  }

  return (
    <div className="flex flex-col">
      {menus.map((menu) => {
        if (menu.menuItems.length === 0) {
          return null
        }
        return (
          <div key={menu.category.id} className="bg-white shadow rounded-lg divide-y divide-gray-200">
            <div className="snap-start px-4 py-5 sm:px-6" id={menu.category.id}>
              {menu.category.name[selectedLang]}
            </div>
            <div className="px-4 py-5 sm:p-6">
              {menu.menuItems.map((menuItem) => {
                return (
                  menuItem && (
                    <div
                      key={menuItem.id}
                      className="group relative bg-white border border-gray-200 rounded-lg flex flex-col mb-4 shadow-md"
                    >
                      {menuItem?.imageUrl && (
                        <div className="aspect-w-3 aspect-h-4 bg-gray-200 group-hover:opacity-75 sm:aspect-none sm:h-96">
                          <img
                            src={menuItem.imageUrl}
                            alt={menuItem?.name[selectedLang]}
                            className="w-full h-full object-center object-cover sm:w-full sm:h-full"
                          />
                        </div>
                      )}
                      <div className="flex-1 p-4 space-y-2 flex flex-col">
                        <h3 className="text-sm font-medium text-gray-900">
                          <span aria-hidden="true" className="absolute inset-0" />
                          {menuItem?.name[selectedLang]}
                        </h3>
                        <p className="text-sm text-gray-500">{menuItem?.description[selectedLang]}</p>
                        <span className="inline">
                          <AllergenIcons tags={menuItem?.tags} />
                        </span>
                        <p className="text-base font-medium text-gray-900 text-right">
                          {priceDisplay(menuItem?.priceType, parseFloat(menuItem?.price), menuItem?.multiPrice)}
                        </p>
                      </div>
                    </div>
                  )
                )
              })}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default MenuItems
