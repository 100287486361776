import { Popover, Transition } from "@headlessui/react"
import { ChevronDownIcon } from "@heroicons/react/outline"
import { Language, LocationWithMenu } from "../../index.d"
import * as React from "react"
import { classNames } from "../../helpers"

interface LanguagePopoverProps {
  selectedLang: string
  languages: Language[]
  setSelectedLang: (lang: string) => void
}

const LanguagePopover = (props: LanguagePopoverProps) => {
  const { selectedLang, setSelectedLang, languages } = props

  return (
    <Popover className="relative">
      {({ open, close }) => (
        <>
          <Popover.Button
            className={classNames(
              open ? "text-gray-900" : "text-gray-700",
              "group bg-none rounded-md inline-flex items-center text-sm font-medium hover:text-gray-900 focus:outline-none focus:ring-0 focus:ring-offset-0 focus:none"
            )}
          >
            <span>{selectedLang.toUpperCase()}</span>
            <ChevronDownIcon
              className={classNames(open ? "text-gray-600" : "text-gray-400", "ml-2 h-5 w-5 group-hover:text-gray-500")}
              aria-hidden="true"
            />
          </Popover.Button>

          <Transition
            as={React.Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute top-14 left-0 z-10 transform -translate-x-full mt-3 px-2 w-screen max-w-xs sm:px-0">
              <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                <div className="relative grid gap-6 bg-white px-10 py-6 sm:gap-8 sm:p-8">
                  {languages.length > 0 &&
                    languages.map((language) => {
                      return (
                        language && (
                          <button
                            key={language.id}
                            onClick={() => {
                              setSelectedLang(language.id)
                              close()
                            }}
                            className="-m-3 p-3 block rounded-md hover:bg-indigo-50 transition ease-in-out duration-150"
                          >
                            <p className="text-base font-medium text-gray-900">{language.name}</p>
                          </button>
                        )
                      )
                    })}
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  )
}

interface RestoHeaderProps {
  location: LocationWithMenu
  selectedLang: string
  setSelectedLang: (languageId: string) => void
}

const MenuHeader = (props: RestoHeaderProps) => {
  const { selectedLang, setSelectedLang, location } = props
  return (
    <div className="mb-2 bg-indigo-400 h-20 p-4">
      <p className="text-white text-2xl text-left ">{location.name}</p>
      <p className="text-white text-left">
        {location.address}, {location.postalCode} {location.city}
      </p>
      <p className="text-white text-left">{location.phone}</p>
      <div className="float-right">
        <LanguagePopover selectedLang={selectedLang} setSelectedLang={setSelectedLang} languages={location.availableLangs} />
      </div>
    </div>
  )
}

export default MenuHeader
